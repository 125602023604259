import React from 'react';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import { Breadcrumbs } from '@mui/material';
import Layout from '../components/Layout';
import { WindowLocation } from "@reach/router";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { appInsightClient } from '../utils/AppInsightApiCall';
import { useSnackbar } from 'notistack';
import { Helmet } from "react-helmet";
import TextField from '@mui/material/TextField';

type RatingProps = {
    name: string,
    value: number | null
}

type FeedbackProps = {
    criteria: string,
    description: string,
}

const FeedbackData: FeedbackProps[] = [
    {
        criteria: "Coverage",
        description: "SEM Wiki provides extensive content"
    },
    {
        criteria: "Accuracy",
        description: "SEM Wiki content is correct and precise"
    },
    {
        criteria: "Relevance",
        description: "SEM Wiki provides the content I need to answer my questions"
    },
    {
        criteria: "Ease of Use",
        description: "SEM Wiki is easy to use"
    },
    {
        criteria: "Look",
        description: "SEM Wiki is visually appealing"
    }
]

const Feedback: React.FC<{ location: WindowLocation }> = React.memo(function Feedback(props) {
    const [ratings, setRatings] = React.useState<RatingProps[]>([])
    const [feedbackStatus, setFeedbackStatus] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar()
    const [comment, setComment] = React.useState<string>('');

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    const getRatingValue = (criteria: string) => {
        const rating = ratings.find(rate => rate.name === criteria);
        return rating ? rating.value : null;
    };

    const handleGoToHome = () => {
        window.location.href = '/';
    };

    const handleSubmitNewFeedback = () => {
        window.location.href = '/feedback';
    };


    const handleRatingValues = (value: number | null, criteria: string, index: number) => {
        if (!feedbackStatus) {
            const newRatings = [...ratings];
            newRatings[index] = {
                name: criteria,
                value: value
            };
            setRatings(newRatings);
        }
    }

    const handleSendFeedback = () => {
        const checkNullValue = ratings.some(rate => rate.value === null)
        if (!feedbackStatus) {
            if (ratings.length === FeedbackData.length && !checkNullValue) {
                const feedbackData = [...ratings, { name: 'comment', value: comment }];
                appInsightClient.trackEvent({ name: 'ratings' }, feedbackData);
                setFeedbackStatus(true);
            } else {
                enqueueSnackbar("Please rate all the criteria", { variant: 'info', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } });
            }
        } else {
            enqueueSnackbar("You already submitted your feedback", { variant: 'info', anchorOrigin: { vertical: 'bottom', horizontal: 'right' } });
        }
    }

    const renderBreadcrumbs = () => (
        <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '40px', color: '#666666' }}>
            <Button size='small' style={{
                fontWeight: '400px', fontSize: '14px', lineHeight: '16px',
                textTransform: 'capitalize', margin: '0px', minWidth: '0px'
            }} href='/'>
                Home
            </Button>
            <Typography style={{ fontWeight: '400px', fontSize: '14px', lineHeight: '16px' }}>
                Feedback
            </Typography>
        </Breadcrumbs>
    );

    const renderHeader = () => {
        if (feedbackStatus) {
            return (
                <Box sx={{ pt: 5 }}>
                    <Typography fontSize="32px" fontWeight="700" color={(theme) => theme.palette.darkColors.black80}>
                        Thank You!
                    </Typography>
                    <Typography fontSize="16px" maxWidth="737px" align="justify" color={(theme) => theme.palette.darkColors.black60}>
                        Your feedback has been submitted.
                    </Typography>
                </Box>
            );
        } else {
            return (
                <Box sx={{ pt: 5 }}>
                    <Typography fontSize="32px" fontWeight="700" color={(theme) => theme.palette.darkColors.black80}>
                        Leave Your Feedback
                    </Typography>
                    <Typography fontSize="16px" maxWidth="737px" align="justify" color={(theme) => theme.palette.darkColors.black60}>
                        Our team is always looking for ways to improve SEM Wiki; for this reason, we welcome your feedback at any moment. Use this form below if you have a suggestion, you want to tell us how we can improve the SEM Wiki experience, or if you want to send us compliments - we always love compliments :)
                    </Typography>
                </Box>
            );
        }
    };

    const renderCriteriaRatings = () => (
        <Grid container flexDirection="column" sx={{ pt: 3 }}>
            {FeedbackData.map((items, index) => (
                <Grid container key={index} display="flex" flexDirection="row" sx={{ pt: 3, pr: 2 }}>
                    <Grid item lg={2} md={12} sm={12} xs={12}>
                        <Typography align="left" width="150px" color={theme => theme.palette.darkColors.black80}>{items.criteria}</Typography>
                        <Typography fontSize="12px" color={theme => theme.palette.darkColors.black60}>{items.description}</Typography>
                    </Grid>
                    <Grid item alignSelf="center" sx={{ pl: { xl: 2, lg: 2, md: 0, sm: 0, xs: 0 } }}>
                        <Rating
                            name={items.criteria}
                            max={5}
                            sx={{ float: "right", fontSize: "1.9rem" }}
                            value={feedbackStatus ? getRatingValue(items.criteria) : ratings[index]?.value}
                            readOnly={feedbackStatus}
                            onChange={(event, value) => handleRatingValues(value, items.criteria, index)}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );

    const renderCommentField = () => (
        <Grid item sx={{ pt: 4 }}>
            <Typography fontSize="16px" color={theme => theme.palette.darkColors.black60}>Your Feedback</Typography>
            <TextField
                id="comment"
                label="Comments"
                multiline
                rows={4}
                value={comment}
                onChange={handleCommentChange}
                variant="outlined"
                sx={{
                    mt: 2,
                    width: {
                        xs: '100%',
                        sm: '445px',
                    },
                    height: feedbackStatus ? 'auto' : '120px',
                    backgroundColor: feedbackStatus ? '#CCCCCC' : undefined,
                    outlineColor: feedbackStatus ? '#CCCCCC' : undefined, 
                }}
                disabled={feedbackStatus}
            />
        </Grid>
    );

    const renderSubmitButton = () => (
        <Grid item sx={{ pt: 4 }}>
            {feedbackStatus ? (
                <>
                    <Button variant='contained' onClick={() => handleGoToHome()}
                        sx={{ mr: 2, bgcolor: '#008200', borderRadius: '7px' }}>
                        <Typography color='#FFFFFF' fontSize='14px' sx={{ weight: '700' }}>
                            GO TO HOME
                        </Typography>
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmitNewFeedback()}
                        sx={{
                            bgcolor: '#FFFFFF',
                            border: '1px solid #008200',
                            borderRadius: '7px',
                            '&:hover': {
                                bgcolor: '#F3F3F3',
                            },
                        }}
                    >
                        <Typography color="#008200" fontSize="14px" sx={{ fontWeight: '700' }}>
                            SUBMIT NEW FEEDBACK
                        </Typography>
                    </Button>

                </>
            ) : (
                <Button variant="contained" onClick={() => handleSendFeedback()} disabled={feedbackStatus}>
                    {feedbackStatus ? 'Feedback Submitted' : 'Submit Feedback'}
                </Button>
            )}
        </Grid>
    );

    return (
        <Layout location={props.location}>
            <Helmet title="SEM Wiki - Feedback" defer={false} />
            <Grid container display='flex' flexDirection="column" sx={{ padding: "30px 80px 80px 80px" }}>
                <Grid item>
                    {renderBreadcrumbs()}
                </Grid>
                <Grid item>
                    {renderHeader()}
                </Grid>
                <Grid item>
                    <Grid container display='flex' flexDirection="column">
                        {renderCriteriaRatings()}
                        {renderCommentField()}
                        {renderSubmitButton()}
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
});

export default Feedback;
